import { Dispatch, SetStateAction } from "react";

import {
  ButtonsContainer,
  LoadingContainer,
  SingleButtonContainer,
} from "./styles";

import { useAppTabsContext } from "src/contexts/TabsContext";
import { useAppContext } from "src/contexts/AppContext";

import { saveFAQs } from "./../../services/save/saveFAQs";
import { saveGreetings } from "./../../services/save/saveGreetings";

import tabsTexts from "src/texts/tabsTexts.json";

import { SendTrackProps } from "src/types/SendTrackProps";
import { FAQField } from "src/types/FAQField";

import Button from "../Button";
import { Resource } from "src/types/Resource";
import { mountKey } from "src/utils/mountKey";
import { sendTrackingButtonHandleCancelAlert } from "src/utils/tracking/sendTrackingButtonHandleCancelAlert";

import { TEXT_TYPE } from "src/constants/Application";
import { saveCallButton } from "../../services/save/saveCallButton";

type ButtonSubmitProps = {
  cancelWarning: boolean;
  setCancelWarning: Dispatch<SetStateAction<boolean>>;
};

export const SubmitButton = ({
  cancelWarning,
  setCancelWarning,
}: ButtonSubmitProps): JSX.Element => {
  const { userData, routerData, activationOption } = useAppContext();
  const { resources, setResources } = useAppTabsContext();

  const {
    previousTab,
    setPreviousTab,
    returnMessage,
    firstContactMessage,
    greetings,
    setGreetings,
    queueList,
    hasFaq,
    faqList,
    FAQ,
    setFAQ,
    twentyFourHours,
    buttonMessage,
    setCallButton,
    callButton,
    research,
  } = useAppTabsContext();

  const tabsIsLoading = () => {
    return (
      (greetings && greetings.isLoading) ||
      (FAQ && FAQ.isLoading) ||
      (callButton && callButton.isLoading)
    );
  };

  const tabsIsInvalid = () => {
    switch (previousTab) {
      case tabsTexts.customizableMenu.name:
        return hasFaq && (FAQ && FAQ.isInvalid);
      case tabsTexts.callButton.name:
        return callButton && callButton.isInvalid;
      default:
        return greetings && greetings.isInvalid;
    }
  };

  const configTrackingSave = (): SendTrackProps => {
    return {
      merchantName: userData.name,
      numQueue: queueList.length,
      numQuestions: faqList.length,
      service24h: twentyFourHours,
      statusskill: `pesquisa satisfacao ${research}`,
    };
  };

  const handleClickButton = async () => {
    const tabs = document.getElementsByTagName("bds-tab");
    const routerKey =
      routerData.shortName && routerData.accessKey
        ? mountKey(routerData.shortName, routerData.accessKey)
        : undefined;

    for (let i = 0; i < tabs.length; i++) {
      if (/bds-tab--selected/g.test(tabs[i].className)) {
        switch (tabs[i].label) {
          case tabsTexts.callButton.name:
            setCallButton((current) => {
              return {
                ...current,
                isLoading: true,
                isOpenModalSuccess: false,
              };
            });
            saveCallButton({
              routerData,
              trackConfig: configTrackingSave(),
              keyRouter: routerKey || "",
              setCallButton,
              messages: [buttonMessage],
            }).then(() => {
              setCallButton((current) => {
                return {
                  ...current,
                  isLoading: false,
                };
              });
              updateResources(tabsTexts.callButton.name);
            });
            break;
          case tabsTexts.saudation.name:
            setGreetings((current) => {
              return {
                ...current,
                isLoading: true,
                isOpenModalSuccess: false,
              };
            });

            saveGreetings({
              routerData,
              trackConfig: configTrackingSave(),
              keyRouter: routerKey || "",
              setGreetings,
              messages: [returnMessage, firstContactMessage],
            }).then(() => {
              setGreetings((current) => {
                return {
                  ...current,
                  isLoading: false,
                };
              });
              updateResources(tabsTexts.saudation.name);
            });
            break;
          case tabsTexts.customizableMenu.name: {
            setFAQ((current) => {
              return {
                ...current,
                isLoading: true,
                isOpenModalSuccess: false,
              };
            });

            const oldFAQsResources = resources.filter((resource) => {
              if (/[1-5]\.\s\w+/.test(resource.name)) {
                return resource;
              }

              return [];
            });

            const oldFAQs: FAQField[] = (oldFAQsResources as Resource[]).map(
              (oldFAQsResource) => {
                return {
                  topic: oldFAQsResource.name.slice(3),
                  answer: oldFAQsResource.value,
                };
              }
            );

            saveFAQs({
              hasFaq: hasFaq.toString(),
              newFAQs: faqList,
              oldFAQs,
              routerAuth: mountKey(routerData.shortName, routerData.accessKey),
              routerData,
              trackConfig: configTrackingSave(),
              setFAQ,
            }).then(() => {
              setFAQ((current) => {
                return {
                  ...current,
                  isLoading: false,
                };
              });

              updateResources(tabsTexts.customizableMenu.name);
            });
          }
        }
      }
    }
  };

  const updateResources = (tab: string) => {
    switch (tab) {
      case tabsTexts.saudation.name:
        setResources([
          {
            name: "returnMessage",
            value: returnMessage,
            type: TEXT_TYPE,
          },
          {
            name: "firstContactMessage",
            value: firstContactMessage,
            type: TEXT_TYPE,
          },
        ]);
        break;
      case tabsTexts.callButton.name:
        setResources([
          {
            name: "buttonMessage",
            value: buttonMessage,
            type: TEXT_TYPE,
          },
        ]);
        break;
      case tabsTexts.customizableMenu.name:
        let newResources: Resource[] = [];

        newResources = faqList.map((faq, i) => {
          const name = `${i + 1}. ${faq.topic}`;
          return {
            name,
            type: TEXT_TYPE,
            value: faq.answer,
          };
        });

        newResources.push({
          name: "semFaq",
          value: hasFaq.toString(),
          type: TEXT_TYPE,
        });

        setResources(newResources);
        break;
    }
  };

  const handleCancelAlert = async () => {
    setCancelWarning(!cancelWarning);

    if (!previousTab) {
      setPreviousTab(tabsTexts.saudation.name);
    }

    sendTrackingButtonHandleCancelAlert(
      previousTab ? previousTab : tabsTexts.saudation.name,
      cancelWarning,
      userData.name,
      activationOption
    );
  };

  return (
    <ButtonsContainer>
      <SingleButtonContainer>
        <Button
          text="Cancelar"
          isFAQ={false}
          isAdd={false}
          onClick={handleCancelAlert}
          isSubmit={false}
          isCancel
          isDisabled={tabsIsLoading()}
        />

        {tabsIsLoading() ? (
          <LoadingContainer>
            <bds-loading-spinner color="main" size="small" />
          </LoadingContainer>
        ) : (
          <Button
            text="Salvar alterações"
            isFAQ={false}
            isAdd={false}
            onClick={handleClickButton}
            isSubmit={false}
            isDisabled={tabsIsInvalid()}
          />
        )}
      </SingleButtonContainer>
    </ButtonsContainer>
  );
};
