import { api } from "./Api";
import { INSTALLATION_URL, TENANTS_ID_URL } from "./constants/Application";

async function getApplicationInstallation(
  accountToken: string,
  botShortName: string
) {
  try {
    const client = api(accountToken);
    const response = await client.get(`${INSTALLATION_URL}=${botShortName}`);

    if (response.data.status === "failure") {
      console.error(response.data);
    }

    return response.data;
  } catch (error) {
    console.error(error);
  }
}

async function getPlan(accountToken: string, botShortName: string) {
  try {
    const client = api(accountToken);
    const response = await client.get(`${TENANTS_ID_URL}/${botShortName}`);

    if (response.data.status === "failure") {
      console.error(response.data);
    }

    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export const ApplicationService = {
  getApplicationInstallation,
  getPlan,
};
