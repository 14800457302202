import axios from "axios";
import { CurrentEnvironment } from "src/configs/Environment";

export const api = (accountToken: string) => {
  const url =
    CurrentEnvironment === "production"
      ? "https://takeblipapps.blip.tools"
      : "https://takeblipapps-hmg.blip.tools";

  return axios.create({
    baseURL: url,
    headers: {
      Authorization: `Bearer ${accountToken}`,
    },
  });
};
