import React, { FC, createContext, useState, useContext } from "react";

import { Tabs } from "src/types/Tabs";
import { FAQField } from "src/types/FAQField";
import { Queue } from "src/types/Queue";
import { Resource } from "src/types/Resource";

type AppTabsContextData = {
  previousTab: string;
  setPreviousTab: React.Dispatch<React.SetStateAction<string>>;
  returnMessage: string;
  setReturnMessage: React.Dispatch<React.SetStateAction<string>>;
  firstContactMessage: string;
  setFirstContactMessage: React.Dispatch<React.SetStateAction<string>>;
  buttonMessage: string;
  setButtonMessage: React.Dispatch<React.SetStateAction<string>>;
  queueList: Queue[];
  setQueueList: React.Dispatch<React.SetStateAction<Queue[]>>;
  greetings: Tabs;
  setGreetings: React.Dispatch<React.SetStateAction<Tabs>>;
  hasFaq: boolean;
  setHasFaq: React.Dispatch<React.SetStateAction<boolean>>;
  faqList: FAQField[];
  setFaqList: React.Dispatch<React.SetStateAction<FAQField[]>>;
  FAQ: Tabs;
  setFAQ: React.Dispatch<React.SetStateAction<Tabs>>;
  twentyFourHours: boolean;
  setTwentyFourHours: React.Dispatch<React.SetStateAction<boolean>>;
  research: boolean;
  setResearch: React.Dispatch<React.SetStateAction<boolean>>;
  callButton: Tabs;
  setCallButton: React.Dispatch<React.SetStateAction<Tabs>>;
  resources: Resource[];
  setResources: React.Dispatch<React.SetStateAction<Resource[]>>;
};

export const AppTabsContext = createContext<AppTabsContextData>(
  {} as AppTabsContextData
);

export const AppTabsProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [previousTab, setPreviousTab] = useState("");

  // Saudacao
  const [returnMessage, setReturnMessage] = useState("");
  const [firstContactMessage, setFirstContactMessage] = useState("");
  const [buttonMessage, setButtonMessage] = useState("");
  const [greetings, setGreetings] = useState<Tabs>({
    isInvalid: false,
    isOpenWarning: false,
    isModified: false,
    isOpenModalSuccess: false,
    isLoading: false,
    isOpenAlertModify: false,
    handleCloseWarning: () => {
      setGreetings((current) => {
        return {
          ...current,
          isOpenWarning: false,
        };
      });
    },
  });

  // CALL BUTTON
  const [callButton, setCallButton] = useState<Tabs>({
    isInvalid: false,
    isOpenWarning: false,
    isModified: false,
    isOpenModalSuccess: false,
    isLoading: false,
    isOpenAlertModify: false,
    handleCloseWarning: () => {
      setCallButton((current) => {
        return {
          ...current,
          isOpenWarning: false,
        };
      });
    },
  });

  // FAQ
  const [hasFaq, setHasFaq] = useState(false);
  const [faqList, setFaqList] = useState<FAQField[]>([]);
  const [FAQ, setFAQ] = useState<Tabs>({
    isInvalid: false,
    isOpenWarning: false,
    isModified: false,
    isOpenModalSuccess: false,
    isLoading: false,
    isOpenAlertModify: false,
    handleCloseWarning: () => {
      setFAQ((current) => {
        return {
          ...current,
          isOpenWarning: false,
        };
      });
    },
  });

  // Fila de atendimento
  const [queueList, setQueueList] = useState<Queue[]>([]);

  // Horario
  const [twentyFourHours, setTwentyFourHours] = useState<boolean>(false);

  const [research, setResearch] = useState<boolean>(false);
  const [resources, setResources] = useState<Resource[]>([]);

  return (
    <AppTabsContext.Provider
      value={{
        previousTab,
        setPreviousTab,
        returnMessage,
        setReturnMessage,
        firstContactMessage,
        setFirstContactMessage,
        buttonMessage,
        setButtonMessage,
        queueList,
        setQueueList,
        greetings,
        setGreetings,
        hasFaq,
        setHasFaq,
        faqList,
        setFaqList,
        FAQ,
        setFAQ,
        twentyFourHours,
        setTwentyFourHours,
        callButton,
        setCallButton,
        research,
        setResearch,
        resources,
        setResources
      }}
    >
      {children}
    </AppTabsContext.Provider>
  );
};

export function useAppTabsContext(): AppTabsContextData {
  const context = useContext(AppTabsContext);

  if (!context) {
    throw new Error("use app context must be used within an AppProvider");
  }

  return context;
}
