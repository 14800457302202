import { RESOURCES } from "src/constants/ResourcesNames";
import { Resource } from "src/types/Resource";

interface resetCallButtonProps {
  resources: Resource[];
  setButtonMessage: React.Dispatch<React.SetStateAction<string>>;
}

export const resetCallButton = ({
  resources,
  setButtonMessage,
}: resetCallButtonProps) => {
  resources.forEach((resource) => {
    const { name, value } = resource;

    if (name === RESOURCES.MESSAGES.BUTTON_MESSAGE) {
      setButtonMessage(value);
    }
  });
};
