import React, { useEffect, useState } from "react";
import { Tabs } from "src/types/Tabs";
import { Container } from "./styles";

type ModalSuccessProps = {
  text: string;
  open: boolean;
  setTabData: React.Dispatch<React.SetStateAction<Tabs>>;
};

export const ModalSuccess = ({ text, open, setTabData }: ModalSuccessProps) => {
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (open) {
      setShowToast(true);
    }
  }, [open, setTabData]);

  return (
    <Container>
      <bds-toast
        icon="like"
        action-type="icon"
        toast-text={text}
        variant="success"
        show={showToast}
      ></bds-toast>
    </Container>
  );
};
