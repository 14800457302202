import { validateTextInput } from "./validateTextInput";

export const validateGreetings = (
  returnMessage: string,
  firstContactMessage: string
) => {
  return (
    validateTextInput(returnMessage) && validateTextInput(firstContactMessage)
  );
};
