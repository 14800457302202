export const validateTexts = (messages: string[]) => {
    let isPassed = true;

    messages.forEach(message => {
        if (
            message === '' ||
            message === undefined ||
            message === null
        ) {
            isPassed = false;
        }
        
    })

    return isPassed;
};
