import Guid from "guid";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";

import { LoadingContainer, MainContainer, TabsPanelContainer } from "./styles";

import { useAppContext } from "src/contexts/AppContext";
import { useAppTabsContext } from "src/contexts/TabsContext";

import SubmitButton from "../SubmitButton";
import { AlertsButtonCancel } from "../Alerts/AlertsButtonCancel";
import { resetFAQs } from "../../services/reset/resetFAQs";
import { resetGreetings } from "../../services/reset/resetGreetings";
import { resetCallButton } from "../../services/reset/resetCallButton";
import { api } from "src/services/requests/Api";
import { Requests } from "src/services/requests/Requests";
import { getAttendanceQueues } from "../../services/getAttendanceQueues";
import { RESOURCES } from "src/constants/ResourcesNames";
import tabsTexts from "src/texts/tabsTexts.json";
import { mountKey } from "src/utils/mountKey";
import { validateGreetings } from "src/utils/validates/validateGreetings";
import { validateCallButton } from "src/utils/validates/validateCallButton";

import { Menu } from "../../contents/Menu";
import { CallButton } from "../../contents/CallButton";
import { Saudation } from "../../contents/Saudation";

import { ModuleBotConfigurationTracks } from "src/pages/ModuleBotConfiguration/services/trackings/handleSendTrackings";

export const Tabs = () => {
  const { userData, routerData } = useAppContext();
  const {
    setPreviousTab,
    returnMessage,
    setReturnMessage,
    firstContactMessage,
    setFirstContactMessage,
    setGreetings,
    setQueueList,
    setFaqList,
    setTwentyFourHours,
    buttonMessage,
    setButtonMessage,
    setCallButton,
    setResearch,
    setHasFaq,
    resources,
    setResources
  } = useAppTabsContext();

  const [request, setRequest] = useState<Requests | undefined>();
  const [requestRouterResources, setRequestWithResources] = useState<
    Requests | undefined
  >();

  const [cancelWarning, setCancelWarning] = useState(false);
  const [loadingResources, setLoadingResources] = useState(true);

  useEffect(() => {
    const transhipmentKey =
      routerData.skillTransbordo &&
        routerData.skillTransbordo.accessKey &&
        routerData.skillTransbordo.shortName
        ? mountKey(
          routerData.skillTransbordo.shortName,
          routerData.skillTransbordo.accessKey
        )
        : undefined;

    const routerKey =
      routerData.shortName && routerData.accessKey
        ? mountKey(routerData.shortName, routerData.accessKey)
        : undefined;

    const request = transhipmentKey
      ? new Requests(api(routerData.skillTransbordo.shortName, transhipmentKey))
      : undefined;
    setRequest(request);

    const requestRouterResources = routerKey
      ? new Requests(api(routerData.shortName, routerKey))
      : undefined;
    setRequestWithResources(requestRouterResources);
  }, [routerData]);

  useEffect(() => {
    setGreetings((current) => {
      return {
        ...current,
        isInvalid: !validateGreetings(returnMessage, firstContactMessage),
      };
    });
  }, [returnMessage, firstContactMessage, setGreetings]);

  useEffect(() => {
    setCallButton((current) => {
      return {
        ...current,
        isInvalid: !validateCallButton(buttonMessage),
      };
    });
  }, [buttonMessage, setCallButton]);

  const searchResources = useCallback(
    async (variablesResource: string[]) => {
      const resourcesFromBot = [];

      for (const resource of variablesResource) {
        const resourceValue = await requestRouterResources?.getSpecificResource(
          Guid.raw(),
          resource
        );
        const newResource = {
          name: decodeURIComponent(resource),
          value: resourceValue.resource,
          type: resourceValue.type,
        };
        resourcesFromBot.push(newResource);
      }
      setResources(resourcesFromBot);
      setLoadingResources(false);
    },
    [requestRouterResources, setResources]
  );

  const getAndSetResources = useCallback(
    async (step: string) => {
      switch (step) {
        case tabsTexts.saudation.name:
          searchResources([
            RESOURCES.MESSAGES.FIRST_CONTACT,
            RESOURCES.MESSAGES.RETURN,
            RESOURCES.TWENTY_FOUR_HOURS,
            RESOURCES.RESEARCH_SATISFACTION,
          ]);

          const attendanceQueues = await getAttendanceQueues(request);
          setQueueList(attendanceQueues);
          break;
        case tabsTexts.customizableMenu.name:
          const resources = await requestRouterResources?.getAllResources(
            Guid.raw()
          );
          const faqListNames: string[] = [];

          resources?.resource?.items?.forEach((resource: string) => {
            if (/\d\.\s\w+/.test(resource)) {
              faqListNames.push(resource);
            }
          });

          searchResources([...faqListNames, RESOURCES.WITHOUT_FAQ]);
          break;
        case tabsTexts.callButton.name:
          searchResources([RESOURCES.MESSAGES.BUTTON_MESSAGE]);
          break;
      }
    },
    [request, requestRouterResources, searchResources, setQueueList]
  );

  const setInitialInputValues = () => {
    if (resources.length > 0) {
      resetGreetings({
        resources,
        setReturnMessage,
        setFirstContactMessage,
      });

      resetCallButton({
        resources,
        setButtonMessage,
      });

      resetFAQs({
        resources,
        setFaqList,
      });

      resources.map((resource) => {
        if (resource.name === "openHour") {
          setTwentyFourHours(resource.value === "true");
        }

        if (resource.name === RESOURCES.RESEARCH_SATISFACTION) {
          setResearch(resource.value === "true");
        }

        if (resource.name === 'semFaq') {
          setHasFaq(resource.value === "true");
        }

        return false;
      });
    }
  };

  const handlePreviousTab = (label: string) => {
    const tabs = document.getElementsByTagName("bds-tab");

    for (let i = 0; i < tabs.length; i++) {
      if (/bds-tab--selected/g.test(tabs[i].className)) {
        setPreviousTab(label);
        getAndSetResources(label);
      }

      if (tabs[i].label === label) {
        sendTrackTab(
          userData.fullName || userData.name,
          tabs[i].group
        );
      }
    }
  };

  useLayoutEffect(() => {
    if (requestRouterResources) {
      getAndSetResources(tabsTexts.saudation.name);
    }
  }, [getAndSetResources, requestRouterResources]);

  const sendTrackTab = async (merchantName: string, screenName: string) => {
    const moduleBotConfigurationTracks = new ModuleBotConfigurationTracks();
    await moduleBotConfigurationTracks.sendTrackTab({ merchantName, screenName });
  };

  useEffect(() => {
    if (requestRouterResources) {
      getAndSetResources(tabsTexts.saudation.name);
    }
  }, [getAndSetResources, requestRouterResources, userData]);

  useEffect(() => {
    setInitialInputValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resources]);

  return (
    <MainContainer>
      {loadingResources ? (
        <LoadingContainer style={{ alignSelf: "center" }}>
          <bds-loading-spinner color="main" size="small" />
        </LoadingContainer>
      ) : (
        <>
          <bds-tabs align="left">
            <bds-tab
              group="greetings"
              label="Saudação"
              onClick={() => handlePreviousTab(tabsTexts.saudation.name)}
            />

            <bds-tab
              group="menu"
              label="Menu"
              onClick={() => handlePreviousTab(tabsTexts.customizableMenu.name)}
            />

            <bds-tab
              group="attendance-button"
              label="Botão de atendimento"
              onClick={() => handlePreviousTab(tabsTexts.callButton.name)}
            />
          </bds-tabs>

          <TabsPanelContainer>
            <bds-tab-panel class="groups" group="greetings">
              <Saudation />
            </bds-tab-panel>

            <bds-tab-panel class="groups" group="menu">
              <Menu />
            </bds-tab-panel>

            <bds-tab-panel class="groups" group="attendance-button">
              <CallButton />
            </bds-tab-panel>
          </TabsPanelContainer>

          <SubmitButton
            cancelWarning={cancelWarning}
            setCancelWarning={setCancelWarning}
          ></SubmitButton>

          <AlertsButtonCancel
            cancelWarning={cancelWarning}
            setCancelWarning={setCancelWarning}
          ></AlertsButtonCancel>
        </>
      )}
    </MainContainer>
  );
};
