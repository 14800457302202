function Add(
  key: string,
  value: string | any,
  replaceIfExists: boolean = true
) {
  if (typeof value !== "string") value = JSON.stringify(value);

  if (Check(key) && !replaceIfExists) return;

  localStorage.setItem(key, value);
}

function Get(key: string) {
  if (!Check(key)) return null;

  let value = localStorage.getItem(key);
  return JSON.parse(value!);
}

function Check(key: string) {
  return localStorage.getItem(key) !== null;
}

function Remove(key: string) {
  if (Check(key)) localStorage.removeItem(key);
}

function RemoveAll() {
  localStorage.clear();
}

export const LocalStorageService = {
  Add,
  Get,
  Check,
  Remove,
  RemoveAll,
};
