import Guid from "guid";
import { api } from "src/services/requests/Api";
import { Requests } from "src/services/requests/Requests";
import { FAQField } from "src/types/FAQField";
import { RouterData } from "src/types/PluginProps";
import { Tabs } from "src/types/Tabs";
import { validateFAQ } from "src/utils/validates/validateFAQ";
import saveResourceData from "./saveResourceData";
import { ModuleBotConfigurationTracks } from "../../services/trackings/handleSendTrackings";
import { WarningConstants } from "src/constants/WarningsConstants";
import { RESOURCES } from "src/constants/ResourcesNames";
import { SendTrackProps } from "src/types/SendTrackProps";
import tabsTexts from "src/texts/tabsTexts.json";
import { TEXT_TYPE } from "src/constants/Application";

interface SaveFAQsProps {
  hasFaq: string;
  setFAQ: React.Dispatch<React.SetStateAction<Tabs>>;
  newFAQs: FAQField[];
  oldFAQs: FAQField[];
  routerAuth: string;
  routerData: RouterData;
  trackConfig: SendTrackProps;
}

export const saveFAQs = async ({
  hasFaq,
  newFAQs,
  setFAQ,
  oldFAQs,
  routerAuth,
  routerData,
  trackConfig,
}: SaveFAQsProps) => {
  const request = new Requests(api(routerData.shortName, routerAuth));

  await saveHasFaq(request, hasFaq);
  await validateFaq(request, newFAQs, oldFAQs, setFAQ, trackConfig);
};

const validateFaq = async (
  request: any,
  newFAQs: FAQField[],
  oldFAQs: FAQField[],
  setFAQ: React.Dispatch<React.SetStateAction<Tabs>>,
  trackConfig: SendTrackProps
) => {
  const moduleBotConfigurationTracks = new ModuleBotConfigurationTracks();

  if (validateFAQ(newFAQs)) {
    await saveOptionsFaq(request, newFAQs, oldFAQs);

    setFAQ((current) => {
      return {
        ...current,
        isInvalid: false,
        isOpenModalSuccess: true,
      };
    });

    await moduleBotConfigurationTracks.sendTrackClickButtonSave({
      screenName: tabsTexts.customizableMenu.name,
      msgWarning: WarningConstants.FAQWARNING,
      ...trackConfig,
      success: false,
      error: `user input error`,
    });
  } else {
    setFAQ((current) => {
      return {
        ...current,
        isInvalid: true,
        isOpenWarning: true,
      };
    });

    await moduleBotConfigurationTracks.sendTrackClickButtonSave({
      screenName: tabsTexts.customizableMenu.name,
      msgWarning: WarningConstants.FAQWARNING,
      ...trackConfig,
      success: false,
      error: `user input error`,
    });
  }
};

const saveOptionsFaq = async (
  request: any,
  newFAQs: FAQField[],
  oldFAQs: FAQField[]
) => {
  const guid = Guid.raw();

  const maxFaqs =
    oldFAQs.length > newFAQs.length ? oldFAQs.length : newFAQs.length;

  for (let i = 0; i < maxFaqs; i++) {
    const oldFaq = oldFAQs[i];
    const newFaq = newFAQs[i];

    const newFaqResourceName = newFaq ? `${i + 1}. ${newFaq.topic}` : "";
    const oldFaqResourceName = oldFaq ? `${i + 1}. ${oldFaq.topic}` : "";

    if (oldFaq !== undefined) {
      if (newFaq !== undefined) {
        const resourceName = `${i + 1}. ${newFaq.topic}`;

        if (oldFaq.topic !== newFaq.topic) {
          await request.deleteResource(oldFaqResourceName, guid);
          await request.setResource(newFaq.answer, newFaqResourceName, guid);
        }

        if (oldFaq.topic === newFaq.topic && oldFaq.answer !== newFaq.answer) {
          await saveResourceData(request, [
            {
              name: resourceName,
              type: "text/plain",
              value: newFaq.answer,
            },
          ]);
        }
      } else {
        await request.deleteResource(oldFaqResourceName, guid);
      }
    } else if (newFaq !== undefined) {
      await request.setResource(newFaq.answer, newFaqResourceName, guid);
    }
  }
};

const saveHasFaq = async (request: any, hasFaq: string) => {
  await saveResourceData(request, [
    {
      name: RESOURCES.WITHOUT_FAQ,
      value: hasFaq,
      type: TEXT_TYPE,
    },
  ]);
};
