
import React, { Component } from "react";

import { AppTabsProvider } from "src/contexts/TabsContext";
import { Tabs } from './components/Tabs';

import { WarningTab } from "./components/Alerts/WarningTab";
import { AlertsTab } from "./components/Alerts/AlertsTab";

export class ModuleBotConfiguration extends Component {

    render() {
        return (
            <AppTabsProvider>
                <Tabs />
                <WarningTab></WarningTab>
                <AlertsTab></AlertsTab>
            </AppTabsProvider>
        );
    }
}