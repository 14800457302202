import { Dispatch, SetStateAction } from "react";

import { useAppTabsContext } from "src/contexts/TabsContext";
import { useAppContext } from "src/contexts/AppContext";
import { ModuleBotConfigurationTracks } from "../../services/trackings/handleSendTrackings";
import { sendTrackingButtonHandleCancelAlert } from "src/utils/tracking/sendTrackingButtonHandleCancelAlert";
import tabsTexts from "src/texts/tabsTexts.json";
import { resetFAQs } from "../../services/reset/resetFAQs";
import { resetGreetings } from "../../services/reset/resetGreetings";
import { resetCallButton } from "../../services/reset/resetCallButton";

type AlertsButtonCancelProps = {
  cancelWarning: boolean;
  setCancelWarning: Dispatch<SetStateAction<boolean>>;
};

export const AlertsButtonCancel = ({
  cancelWarning,
  setCancelWarning,
}: AlertsButtonCancelProps) => {
  const moduleBotConfigurationTracks = new ModuleBotConfigurationTracks();

  const {
    setFirstContactMessage,
    setReturnMessage,
    setFaqList,
    setButtonMessage,
    previousTab,
    setPreviousTab,
    resources
  } = useAppTabsContext();
  const { userData, activationOption } = useAppContext();

  const messageButtonCancel = `Suas alterações de ${previousTab} não serão salvas.`;

  const handleCancelChanges = () => {
    handleClickButton();
    setCancelWarning(!cancelWarning);

    sendTrackingHandlePressYes();
  };

  const handleClickButton = async () => {
    const tabs = document.getElementsByTagName("bds-tab");

    for (let i = 0; i < tabs.length; i++) {
      if (/bds-tab--selected/g.test(tabs[i].className)) {
        switch (tabs[i].label) {
          case tabsTexts.saudation.name:
            resetGreetings({
              resources,
              setReturnMessage,
              setFirstContactMessage,
            });
            break;
          case tabsTexts.callButton.name:
            resetCallButton({
              resources,
              setButtonMessage,
            });
            break;
          case tabsTexts.customizableMenu.name: {
            resetFAQs({
              resources,
              setFaqList,
            });
          }
        }
      }
    }
  };

  const sendTrackingHandlePressYes = async () => {
    await moduleBotConfigurationTracks.sendTrackAlertOpen({
      merchantName: userData.name,
      screenName: previousTab ? previousTab : tabsTexts.saudation.name,
      activationOption,
      msgWarning: messageButtonCancel,
    });
  };

  const handleCancelAlert = async () => {
    setCancelWarning(!cancelWarning);

    if (!previousTab) {
      setPreviousTab(tabsTexts.saudation.name);
    }

    sendTrackingButtonHandleCancelAlert(
      previousTab ? previousTab : tabsTexts.saudation.name,
      cancelWarning,
      userData.name,
      activationOption
    );
  };

  return (
    <>
      <bds-alert open={cancelWarning}>
        <bds-alert-header variant="error" icon="warning">
          Deseja cancelar as alterações?
        </bds-alert-header>
        <bds-alert-body>{messageButtonCancel}</bds-alert-body>
        <bds-alert-actions>
          <bds-button variant="secondary" onClick={handleCancelChanges}>
            Sim
          </bds-button>
          <bds-button variant="secondary" onClick={handleCancelAlert}>
            Não
          </bds-button>
        </bds-alert-actions>
      </bds-alert>
    </>
  );
};
