import { RESOURCES } from "src/constants/ResourcesNames";
import { api } from "src/services/requests/Api";
import { Requests } from "src/services/requests/Requests";
import { RouterData } from "src/types/PluginProps";
import { Tabs } from "src/types/Tabs";
import { validateTexts } from "src/utils/validates/validateTexts";
import saveResourceData from "./saveResourceData";
import { ModuleBotConfigurationTracks } from "../../services/trackings/handleSendTrackings";
import { WarningConstants } from "../../../../constants/WarningsConstants";
import { SendTrackProps } from "src/types/SendTrackProps";
import tabsTexts from "src/texts/tabsTexts.json";

interface SaveGreetingsProps {
  setGreetings: React.Dispatch<React.SetStateAction<Tabs>>;
  messages: string[];
  routerData: RouterData;
  trackConfig: SendTrackProps;
  keyRouter: string;
}

export const saveGreetings = async ({
  messages,
  setGreetings,
  routerData,
  trackConfig,
  keyRouter,
}: SaveGreetingsProps) => {
  const moduleBotConfigurationTracks = new ModuleBotConfigurationTracks();
  const request = new Requests(api(routerData.shortName, keyRouter));

  if (validateTexts(messages)) {
    const [returnMessage, firstContactMessage, buttonMessage] = messages;

    const responses = await saveResourceData(request, [
      {
        name: RESOURCES.MESSAGES.RETURN,
        type: "text/plain",
        value: returnMessage,
      },
      {
        name: RESOURCES.MESSAGES.FIRST_CONTACT,
        type: "text/plain",
        value: firstContactMessage,
      },
    ]);

    setGreetings((current) => {
      return {
        ...current,
        isOpenModalSuccess: true,
      };
    });

    await moduleBotConfigurationTracks.sendTrackClickButtonSave({
      screenName: tabsTexts.saudation.name,
      msgWarning: "none",
      ...trackConfig,
      success: true,
      error: responses.length === 0 ? "The data was not saved" : "none",
    });

    return responses;
  } else {
    setGreetings((current) => {
      return {
        ...current,
        isInvalid: true,
        isOpenWarning: true,
      };
    });

    await moduleBotConfigurationTracks.sendTrackClickButtonSave({
      screenName: tabsTexts.saudation.name,
      msgWarning: WarningConstants.GREETINGSWARNING,
      ...trackConfig,
      success: false,
      error: `user input error`,
    });
  }
};
