import Guid from 'guid';
import { Requests } from 'src/services/requests/Requests';
import { Resource } from 'src/types/Resource';

const saveResourceData = async (request: Requests, resources: Resource[]) => {
    const guid = Guid.raw();
    const responses = [];
    for (const res of resources) {
        const response = await request.setResource(res.value, res.name, guid);
        responses.push({ [res.name]: response });
    }
    return responses;
};

export default saveResourceData;
