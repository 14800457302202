interface GetAllAttendantsProps {
    guid: string
}

export const GetAllAttendants = ({ guid }: GetAllAttendantsProps) => {
    return {
        id: guid,
        to: 'postmaster@desk.msging.net',
        method: 'get',
        uri: '/attendants'
    }
}
