import { ThemeProvider, createTheme } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { _logMain } from "src/configs/LogConfig";
import { QueryStringService as _queryStringService } from "src/services/queryStringService/QueryStringService";
import "./App.scss";

import { AppProvider } from "src/contexts/AppContext";
import { Profile } from "src/types/OIDCProfile";
import { IAppData } from "src/types/PluginProps";

import { AnalyticsService as _analyticsService } from "src/services/analyticsService/AnalyticsService";
import { LocalStorageService as _localStorageService } from "src/services/localStorageService/LocalStorageService";

import { mountKey } from "src/utils/mountKey";

import ModuleBotConfiguration from "src/pages/ModuleBotConfiguration";

import "./font_face.scss";

const _logger = _logMain.getChildCategory("App");
const _userTraits = "userTraits";

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    neutral: Palette["primary"] & { lightest?: string };
    successful?: Palette["primary"] & { lightest?: string };
    warnings?: Palette["primary"] & { lightest?: string };
  }

  interface PaletteOptions {
    neutral: PaletteColorOptions & { lightest?: string };
    successful?: PaletteColorOptions & { lightest?: string };
    warnings?: PaletteColorOptions & { lightest?: string };
  }
}

/**
 * Only use here. This function auto track when page changed.
 */
function TrackPages() {
  let location = useLocation();

  useEffect(() => {
    let link: any = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.head.appendChild(link);
    }
    link.href = process.env.REACT_APP_FAVICON;
  }, [location.pathname]);
}

function analyticsServiceUserTraits(appData: any) {
  const routerKey =
    appData.routerData.shortName && appData.routerData.accessKey
      ? mountKey(appData.routerData.shortName, appData.routerData.accessKey)
      : undefined;

  _analyticsService
    .GetUserTraitsAsync(
      routerKey!,
      appData.accountToken,
      appData.routerData.shortName,
      appData.profile?.fullName! || appData.profile?.name!,
      appData.profile?.email!
    )
    .then((resp) => {
      _localStorageService.Add(_userTraits, resp);
    });
}

function App(): JSX.Element {
  const [appData, setAppData] = useState<IAppData>({} as IAppData);

  useEffect(() => {
    try {
      let appData = _queryStringService.TryGetAppData();

      setAppData({
        accountToken: appData.accountToken,
        profile: appData.profile as Profile,
        routerAccessKey: appData?.routerData?.accessKey,
        routerShortName: appData?.routerData?.shortName,
        transhipmentAccessKey: appData?.routerData?.skillTransbordo?.accessKey,
        transhipmentShortName: appData?.routerData?.skillTransbordo?.shortName,
      });

      analyticsServiceUserTraits(appData);
    } catch (error) {
      _logger.error("Invalid app data!");
    }
  }, []);

  TrackPages();

  const theme = createTheme({
    palette: {
      primary: {
        light: "#D0F1F3",
        main: "#125AD5",
        dark: "#167491",
      },
      secondary: {
        light: "#B3D4FF",
        main: "#202C44",
        dark: "#0747A6",
      },
      neutral: {
        lightest: "#F8FBFB",
        light: "#D2DFE6",
        main: "#6E7B91",
        dark: "#202C44",
      },
      successful: {
        lightest: "#90E6BC",
        light: "#81c784",
        main: "#4caf50",
        dark: "#388e3c",
      },
      warnings: {
        lightest: "#FFF6A8",
        light: "#ffb74d",
        main: "#ff9800",
        dark: "#f57c00",
      },
    },
    typography: {
      fontFamily: "'Nunito Sans', sans-serif",
      h6: {
        fontWeight: 700,
      },
    },
  });

  return (
    <>
      <bds-theme-provider theme="light">
        <ThemeProvider theme={theme}>
          <AppProvider appData={appData}>
            <Routes>
              <Route path="/" element={<ModuleBotConfiguration />} />
              <Route path="/home" element={<ModuleBotConfiguration />} />
            </Routes>
          </AppProvider>
        </ThemeProvider>
      </bds-theme-provider>
    </>
  );
}

export default App;
