import axios from "axios";
import { CurrentEnvironment } from "src/configs/Environment";

export const api = (botShortName: string, auth: string) => {
  const url =
    CurrentEnvironment === "production"
      ? "https://http.msging.net"
      : "https://hmg-http.msging.net";
  console.log("api current environment and url:", CurrentEnvironment, url);
  return axios.create({
    baseURL: url,
    headers: {
      Authorization: auth,
    },
  });
};
