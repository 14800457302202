import { useAppTabsContext } from "src/contexts/TabsContext";
import ModalSuccess from "../ModalSuccess";
import tabsTexts from "src/texts/tabsTexts.json";

export const AlertsTab = () => {
  const { greetings, setGreetings, FAQ, setFAQ, callButton, setCallButton } =
    useAppTabsContext();

  return (
    <>
      {greetings && greetings.isOpenModalSuccess && (
        <ModalSuccess
          setTabData={setGreetings}
          open={greetings.isOpenModalSuccess}
          text={`A ${tabsTexts.saudation.name.toLowerCase()} foi alterada com sucesso.`}
        />
      )}

      {FAQ && FAQ.isOpenModalSuccess && (
        <ModalSuccess
          setTabData={setFAQ}
          open={FAQ.isOpenModalSuccess}
          text={`O ${tabsTexts.customizableMenu.name.toLowerCase()} foi alterada com sucesso.`}
        />
      )}

      {callButton && callButton.isOpenModalSuccess && (
        <ModalSuccess
          setTabData={setCallButton}
          open={callButton.isOpenModalSuccess}
          text={`O ${tabsTexts.callButton.name.toLowerCase()} foi alterada com sucesso.`}
        />
      )}
    </>
  );
};
