import styled from "styled-components";

export const ColumnContainer = styled.div`
  padding: 25px 50px 25px 50px;
  display: flex;
  flex-direction: column;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-itens: center;
`;

export const TextContainer = styled.div`
  margin-bottom: 24px;
`;

export const ImageContainer = styled.div`
  padding: 80px 80px 80px 0px;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  position: relative;
  margin-bottom: 70px;
  min-width: fit-content;
`;

export const TabsPanelContainer = styled.div`
  border: 1px solid #d3dfe6;
  border-radius: 9px;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const SingleButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 350px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
  margin-top: 32px;
  width: 140px;
`;

export const Img = styled.img`
  width: 200px;
  @media screen and (min-width: 1280px) {
    width: 251px;
  }
  @media screen and (min-width: 1440px) {
    width: 262px;
  }

  @media screen and (min-width: 1600px) {
    width: 325px;
  }
`;
