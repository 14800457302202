import { RESOURCES } from "src/constants/ResourcesNames";
import { Resource } from "src/types/Resource";

interface ResetGreetingsProps {
  resources: Resource[];
  setReturnMessage: React.Dispatch<React.SetStateAction<string>>;
  setFirstContactMessage: React.Dispatch<React.SetStateAction<string>>;
}

export const resetGreetings = ({
  resources,
  setReturnMessage,
  setFirstContactMessage,
}: ResetGreetingsProps) => {
  resources.forEach((resource) => {
    const { name, value } = resource;

    if (name === RESOURCES.MESSAGES.RETURN) {
      setReturnMessage(value);
    }

    if (name === RESOURCES.MESSAGES.FIRST_CONTACT) {
      setFirstContactMessage(value);
    }
  });
};
