import React from 'react';
import { MainContainer } from './styles';

type SwitchProps = {
    name: string;
    text: string;
    onClick: (event: any) => void;
    checked?: boolean;
    isEmojiSwitch: boolean;
};

export const Switch = ({ name, text, onClick, checked, isEmojiSwitch }: SwitchProps) => (
    <MainContainer isEmojiSwitch={isEmojiSwitch}>
        <bds-switch name={name} checked={checked} refer="" onClick={onClick} />

        <bds-typo variant="fs-14">{text}</bds-typo>
    </MainContainer>
);
