interface SetResourceProps {
  resourceName: string;
  resourceValue: string;
  guid: string;
}

export const SetResource = ({
  resourceName,
  resourceValue,
  guid,
}: SetResourceProps) => {
  return {
    id: guid,
    method: "set",
    uri: `/resources/${encodeURIComponent(resourceName)}`,
    type: "text/plain",
    resource: resourceValue,
  };
};
