import styled from 'styled-components';


export const MainContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	width: 400px;

	bds-button-icon {
		margin-left: 15px;
	}
`;
