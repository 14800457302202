import styled from "styled-components";

export const HorizontalLine = styled.div`
  border-top: 1px solid #d2dfe6;
  margin-top: 1%;
  margin-bottom: 1%;
`;

export const ColumnContainer = styled.div`
  padding: 25px 50px 25px 50px;
  display: flex;
  flex-direction: column;
`;

export const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px 30px;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

export const RowSwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const ImageContainer = styled.div`
  padding: 80px 80px 80px 0px;
  align-items: center;
`;
