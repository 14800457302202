import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  position: relative;
  margin-bottom: 70px;
  min-width: fit-content;
`;

export const TabsPanelContainer = styled.div`
  border: 1px solid #d3dfe6;
  border-radius: 9px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
  margin-top: 32px;
  width: 140px;
`;
