import { useAppTabsContext } from "src/contexts/TabsContext";
import tabsTexts from "src/texts/tabsTexts.json";
import InputMessageEditable from "src/pages/ModuleBotConfiguration/components/InputMessageEditable";

import {
  ColumnContainer,
  RowContainer,
  TextContainer,
  ImageContainer,
  Img
} from "./../styles";

import saudationGif from "src/assets/images/greetings.gif";

export const Saudation = () => {
  const {
    returnMessage,
    setReturnMessage,
    firstContactMessage,
    setFirstContactMessage,
  } = useAppTabsContext();

  const handleChangeReturnMessage = (ev: Event) => {
    const input = ev.target as HTMLBdsInputElement | null;
    setReturnMessage(input?.value as string);
  };

  const handleChangeFirstContactMessage = (ev: Event) => {
    const input = ev.target as HTMLBdsInputElement | null;
    setFirstContactMessage(input?.value as string);
  };

  return (
    <RowContainer>
      <ColumnContainer>
        <TextContainer>
          <bds-typo variant="fs-16" bold="semi-bold" tag="p">
            Você pode personalizar a saudação inicial do seu chatbot a qualquer
            momento. É só editar as frases abaixo e salvar as alterações.
          </bds-typo>
        </TextContainer>

        <InputMessageEditable
          disabled={false}
          onChange={handleChangeFirstContactMessage}
          label="Saudação ao primeiro contato"
          message={firstContactMessage}
          rows={3}
          required={true}
          requiredErrorMessage={tabsTexts.error.requiredField}
        />

        <InputMessageEditable
          disabled={false}
          onChange={handleChangeReturnMessage}
          label="Saudação de retorno"
          message={returnMessage}
          rows={3}
          required={true}
          requiredErrorMessage={tabsTexts.error.requiredField}
        />
      </ColumnContainer>
      <ImageContainer>
        <Img src={saudationGif} alt="Take Blip" />
      </ImageContainer>
    </RowContainer>
  );
};
