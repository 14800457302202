import React, { createContext, useEffect, useState } from "react";
import { Profile } from "src/types/OIDCProfile";
import { IAppData, RouterData } from "src/types/PluginProps";

type AppContextData = {
  userData: Profile;
  activationOption: string;
  routerData: RouterData;
  accountToken: string;
};

type ContextProps = {
  appData: IAppData;
};

export const AppContext = createContext<AppContextData>({} as AppContextData);

export const AppProvider: React.FC<
  { children: React.ReactNode } & ContextProps
> = ({ children, appData }) => {
  const [accountToken, setAccountToken] = useState<string>("");
  const [userData, setUserData] = useState<Profile>({} as Profile);
  const [routerData, setRouterData] = useState<RouterData>({} as RouterData);
  const [activationOption, setActivationOption] = useState("");

  useEffect(() => {
    let isMounted = false;

    if (!isMounted) {
      setAccountToken(appData?.accountToken || "");
      setUserData(appData?.profile as Profile);
      setRouterData({
        accessKey: appData?.routerAccessKey || "",
        shortName: appData?.routerShortName || "",
        skillTransbordo: {
          accessKey: appData?.transhipmentAccessKey || "",
          shortName: appData?.transhipmentShortName || "",
        },
      });
      setActivationOption("");
    }

    return () => {
      isMounted = true;
    };
  }, [appData]);

  return (
    <AppContext.Provider
      value={{
        userData,
        activationOption,
        routerData,
        accountToken,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export function useAppContext(): AppContextData {
  const context = React.useContext(AppContext);

  if (!context) {
    throw new Error("use app context must be used within an AppProvider");
  }

  return context;
}
