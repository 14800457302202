interface IGetAllResources {
    guid: string;
}

export const GetAllResources = ({ guid }: IGetAllResources) => {
    return {
        id: guid,
        method: 'get',
        uri: '/resources',
    };
};
