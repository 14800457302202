import { useAppTabsContext } from "src/contexts/TabsContext";
import tabsTexts from "src/texts/tabsTexts.json";
import InputMessageEditable from "src/pages/ModuleBotConfiguration/components/InputMessageEditable";


import {
  ColumnContainer,
  RowContainer,
  TextContainer,
  ImageContainer,
  Img
} from "./../styles";

import callButonGif from "src/assets/images/callButon.gif";

export const CallButton = () => {
  const { buttonMessage, setButtonMessage } = useAppTabsContext();

  const handleChangeButtonMessage = (ev: Event) => {
    const input = ev.target as HTMLBdsInputElement | null;
    setButtonMessage(input?.value as string);
  };

  return (
    <RowContainer>
      <ColumnContainer>
        <TextContainer>
          <bds-typo variant="fs-16" bold="bold">
            Botão de atendimento
          </bds-typo>
          <bds-typo variant="fs-16" bold="semi-bold" tag="p">
            Se quiser, você pode personalizar o botão de transferência para
            atendimento humano.
          </bds-typo>
        </TextContainer>

        <InputMessageEditable
          disabled={false}
          onChange={handleChangeButtonMessage}
          label="Nome do botão"
          message={buttonMessage}
          rows={1}
          maxLength={20}
          required={true}
          requiredErrorMessage={tabsTexts.error.requiredField}
        />
      </ColumnContainer>
      <ColumnContainer>
        <ImageContainer>
          <Img src={callButonGif} alt="Take Blip" />
        </ImageContainer>
      </ColumnContainer>
    </RowContainer>
  );
};
