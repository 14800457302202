import React from "react";

import InputText from "../InputText";
import { MainContainer } from "./styles";

type InputMessageEditableProps = {
  onChange: (e: Event) => void;
  onClick?: () => void;
  disabled: boolean;
  message: string;
  label: string;
  rows: number;
  maxLength?: number;
  danger?: boolean;
  errorMessage?: string;
  required?: boolean;
  requiredErrorMessage?: string;
};

export const InputMessageEditable = ({
  onChange,
  onClick,
  message,
  disabled,
  label,
  rows,
  maxLength,
  danger,
  errorMessage,
  required,
  requiredErrorMessage,
}: InputMessageEditableProps) => {
  return (
    <MainContainer>
      <InputText
        value={message}
        onChange={onChange}
        isTextArea
        rows={rows}
        label={label}
        disabled={disabled}
        maxLength={maxLength}
        danger={danger}
        errorMessage={errorMessage}
        required={required}
        requiredErrorMessage={requiredErrorMessage}
      />
    </MainContainer>
  );
};
