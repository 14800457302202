import { BlipCommandBaseRequest } from "../../../base/models/BlipCommandBaseRequest";

const masterUri = "lime://enterprise.master.hosting@msging.net/configuration";
const builderUri = "lime://enterprise.builder.hosting@msging.net/configuration";

export class GetConfigurationRequest extends BlipCommandBaseRequest {
  metadata: {
    "blip_portal.email": string;
  };

  constructor(userMail: string, from?: string, pp?: string) {
    super(
      from !== undefined ? builderUri : masterUri,
      "GET",
      undefined,
      undefined,
      from,
      pp
    );
    this.metadata = { "blip_portal.email": userMail };
  }
}
