import { useState, useEffect } from "react";

import { useAppTabsContext } from "src/contexts/TabsContext";
import tabsTexts from "src/texts/tabsTexts.json";

import {
  ColumnContainer,
  InputsContainer,
  ImageContainer,
  RowContainer,
  RowSwitchContainer
} from "./styles";

import { LoadingContainer, TextContainer, Img } from "./../styles";

import FAQFields from "src/pages/ModuleBotConfiguration/components/FAQFields";

import menuGif from "src/assets/images/menu.gif";
import Switch from "src/pages/ModuleBotConfiguration/components/Switch";

export const Menu = () => {
  const { faqList, setFaqList, setFAQ, hasFaq, setHasFaq } = useAppTabsContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const countFaqListRender = document.getElementsByClassName("faqFields");

    if (countFaqListRender.length >= 1 || faqList.length === 0) {
      setLoading(false);
    }

    if (faqList.length === 0) {
      setFaqList([{ topic: "", answer: "" }]);
    }

  }, [faqList, setFaqList]);

  const handleSetHasFaq = async (event: Event) => {
    const input = event.currentTarget as HTMLBdsSwitchElement | null;
    const value = await input?.getValue();

    setHasFaq(value as boolean);
  };

  return (
    <RowContainer>
      <ColumnContainer>
        <TextContainer>
          <bds-typo variant="fs-16" tag="p">
            {tabsTexts.customizableMenu.description}
          </bds-typo>
        </TextContainer>

        <TextContainer>
          <bds-typo variant="fs-16" tag="p">
            {tabsTexts.customizableMenu.subDescription}
          </bds-typo>
        </TextContainer>

        {loading && (
          <LoadingContainer style={{ alignSelf: "center" }}>
            <bds-loading-spinner color="main" size="small" />
          </LoadingContainer>
        )}

        <RowSwitchContainer>
          <Switch
            text='Desabilitar menu'
            name="swicthHasFaq"
            isEmojiSwitch={false}
            checked={hasFaq}
            onClick={handleSetHasFaq}
          />
        </RowSwitchContainer>

        {!hasFaq &&
          <InputsContainer>
            {faqList &&
              faqList.map((e, i) => {
                return (
                  <FAQFields
                    key={i}
                    index={i}
                    setFAQList={setFaqList}
                    setTabData={setFAQ}
                    FAQList={faqList}
                  />
                );
              })}
          </InputsContainer>
        }
      </ColumnContainer>
      <ImageContainer>
        <Img src={menuGif} alt="Take Blip" />
      </ImageContainer>
    </RowContainer>
  );
};
