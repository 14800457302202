import { InputType } from "blip-ds/dist/types/components/input/input-interface";
import React, { useEffect, useRef } from "react";

type InputTextProps = {
  onChange: (e: Event) => void;
  value?: any;
  placeholder?: string;
  isTextArea?: boolean;
  rows?: number;
  label?: string;
  name?: string;
  type?: InputType;
  disabled?: boolean;
  pattern?: string;
  maxLength?: number;
  danger?: boolean;
  errorMessage?: string;
  required?: boolean;
  requiredErrorMessage?: string;
};

export const InputText = ({
  onChange,
  value,
  placeholder,
  isTextArea,
  rows,
  label,
  name,
  type,
  disabled,
  pattern,
  maxLength,
  danger,
  errorMessage,
  required,
  requiredErrorMessage,
}: InputTextProps) => {
  const inputRf = useRef<null | HTMLBdsInputElement>(null);

  useEffect(() => {
    inputRf.current?.addEventListener("bdsChange", (event) => onChange(event));
  }, [onChange]);

  return (
    <bds-input
      inputName={name}
      pattern={pattern}
      ref={inputRf}
      value={value}
      placeholder={placeholder}
      is-textarea={isTextArea}
      rows={rows}
      label={label}
      type={type}
      disabled={disabled}
      maxlength={maxLength}
      danger={danger}
      error-message={errorMessage}
      required={required}
      required-error-message={requiredErrorMessage}
    />
  );
};
