import { useAppTabsContext } from "src/contexts/TabsContext";

import Warning from "../Warning";
import { WarningConstants } from "src/constants/WarningsConstants";
import tabsTexts from "src/texts/tabsTexts.json";

export const WarningTab = () => {
    const { greetings, FAQ } = useAppTabsContext();

    return (
        <>
            {greetings && greetings.isInvalid && (
                <Warning
                    isOpen={greetings.isOpenWarning}
                    handleIsOpen={greetings.handleCloseWarning}
                    screenName={`Mensagem do ${tabsTexts.saudation.name}`}
                    text={WarningConstants.GREETINGSWARNING}
                />
            )}

            {FAQ && FAQ.isInvalid && (
                <Warning
                    isOpen={FAQ.isOpenWarning}
                    handleIsOpen={FAQ.handleCloseWarning}
                    screenName={tabsTexts.customizableMenu.name}
                    text={WarningConstants.FAQWARNING}
                />
            )}
        </>
    );
};