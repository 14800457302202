import { LogLevels } from "./../constants/LogLevels";
import { Environment } from "./../constants/Environments";

export const CurrentEnvironment =
  process.env.REACT_APP_ENVIRONMENT ?? Environment.Development;
export const CurrentLogLevel =
  process.env.REACT_APP_LOG_LEVEL ?? LogLevels.Info;
export const SegmentKey = process.env.REACT_APP_SEGMENT_KEY ?? "";
export const ModuleName = "Bot";
export const PackName = "blipgo";
