import { FAQField } from "src/types/FAQField";
import { Resource } from "src/types/Resource";

interface ResetFAQsProps {
  resources: Resource[];
  setFaqList: React.Dispatch<React.SetStateAction<FAQField[]>>;
}

export const resetFAQs = ({ resources, setFaqList }: ResetFAQsProps) => {
  setFaqList([]);

  resources.forEach((resource) => {
    const { name, value } = resource;

    if (/[1-5]\.\s\w+/.test(name)) {
      setFaqList((current) => [
        ...current,
        {
          answer: value,
          topic: name.slice(3),
        },
      ]);
    }
  });
};
