import { ModuleBotConfigurationTracks } from "src/pages/ModuleBotConfiguration/services/trackings/handleSendTrackings";
const moduleBotConfigurationTracks = new ModuleBotConfigurationTracks();

export const sendTrackingButtonHandleCancelAlert = async (
  screenName: string,
  cancelWarning: boolean,
  merchantName: string | undefined,
  activationOption: string
) => {
  if (!cancelWarning) {
    await moduleBotConfigurationTracks.sendTrackClickButtonEdit({
      merchantName: merchantName,
      screenName,
      activationOption,
    });
  }
};
