import styled, { css } from "styled-components";

interface MainContainerProps {
  isAdd: boolean;
  isFAQ: boolean;
}

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const SingleButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 350px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
  margin-top: 32px;
  width: 140px;
`;

export const MainContainer = styled.div<MainContainerProps>`
  display: flex;
  justify-content: end;
  width: 100%;

  ${(props) =>
    props.isAdd
      ? props.isFAQ
        ? css`
            bds-button {
              width: 209px;
            }
          `
        : css`
            bds-button {
              width: 256px;
            }
          `
      : css`
          bds-button {
            margin-top: 32px;
          }
        `}
`;
