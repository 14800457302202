import React from "react";
import { MainContainer } from "./styles";

type ButtonProps = {
  onClick?: () => void;
  text: string;
  isSubmit?: boolean;
  isFAQ: boolean;
  isAdd: boolean;
  isCancel?: boolean;
  isDisabled?: boolean;
};

export const Button = ({
  text,
  onClick,
  isSubmit,
  isFAQ,
  isAdd,
  isCancel,
  isDisabled,
}: ButtonProps): JSX.Element => {
  return (
    <MainContainer isAdd={isAdd} isFAQ={isFAQ}>
      <bds-button
        variant={isAdd || isCancel ? "ghost" : "primary"}
        icon={isAdd ? "add" : ""}
        onClick={isDisabled ? () => {} : onClick}
        arrow={isSubmit ? true : false}
        disabled={isDisabled}
      >
        <bds-typo variant="fs-14" bold={isAdd ? "bold" : "regular"}>
          {text}
        </bds-typo>
      </bds-button>
    </MainContainer>
  );
};
