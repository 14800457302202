import { DeleteResource } from "./data/delete/DeleteResource";

import { GetAllAttendanceQueues } from "./data/get/GetAllAttendanceQueues";
import { GetAllResources } from "./data/get/GetAllResources";
import { GetSpecificResource } from "./data/get/GetSpecificResource";
import { GetAllAttendants } from "./data/get/GetAllAttendants";

import { SetResource } from "./data/set/SetResource";

export class Requests {
  private api: any;

  constructor(api: any) {
    this.api = api;
  }

  private async executeRequest(data: any) {
    try {
      const response = await this.api.post("/commands", data);

      if (response.data.status === "failure") {
        console.error(response.data);
      }

      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async setResource(resourceValue: string, resourceName: string, guid: string) {
    const data = SetResource({
      resourceValue,
      resourceName,
      guid,
    });

    return await this.executeRequest(data);
  }

  async deleteResource(resourceName: string, guid: string) {
    const data = DeleteResource({
      resourceName,
      guid,
    });

    return await this.executeRequest(data);
  }

  async getAllResources(guid: string) {
    const data = GetAllResources({ guid });

    return await this.executeRequest(data);
  }

  async getSpecificResource(guid: string, resourceName: string) {
    const data = GetSpecificResource({ guid, resourceName });

    return await this.executeRequest(data);
  }

  async getAllAttendants(guid: string) {
    const data = GetAllAttendants({ guid });

    return await this.executeRequest(data);
  }

  async getAllAttendanceQueues(guid: string) {
    const data = GetAllAttendanceQueues({ guid });

    return await this.executeRequest(data);
  }
}
