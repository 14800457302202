import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";

import AlertDelete from "../AlertDelete";
import InputText from "../InputText";
import Button from "../Button";

import {
  LabelContainer,
  InputContainer,
  MainContainer,
  InputTextContainer,
  ButtonIconContainer,
  ButtonContainer,
} from "./styles";

import { useAppContext } from "src/contexts/AppContext";
import { MAX_FAQS } from "src/constants/Config";
import { Tabs } from "src/types/Tabs";
import { FAQField } from "src/types/FAQField";
import { ModuleBotConfigurationTracks } from "../../services/trackings/handleSendTrackings";
import { validateTextInput } from "src/utils/validates/validateTextInput";
import tabsTexts from "src/texts/tabsTexts.json";

type FAQFieldsProps = {
  setFAQList: Dispatch<SetStateAction<FAQField[]>>;
  setTabData: Dispatch<SetStateAction<Tabs>>;
  index: number;
  FAQList: FAQField[];
};

export const FAQFields = ({
  index,
  setFAQList,
  FAQList,
  setTabData,
}: FAQFieldsProps) => {
  const list = useMemo(() => [...FAQList], [FAQList]);

  const moduleBotConfigurationTracks = new ModuleBotConfigurationTracks();

  const { userData } = useAppContext();

  const [topic, setTopic] = useState(list[index].topic);
  const [answer, setAnswer] = useState(list[index].answer);

  const [isAlert, setIsAlert] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const [isModified, setIsModified] = useState(false);

  const handleClickDeleteButton = async () => {
    setIsAlert(true);
    setIsOpen(true);

    await moduleBotConfigurationTracks.sendTrackClickButtonDelete({
      merchantName: userData.Name,
      success: true,
      screenName: "Menu",
      error: "none",
      type: "menu-topic",
    });
  };

  const handleRemove = async () => {
    setIsModified(true);

    const list = [...FAQList];
    list.splice(index, 1);

    setFAQList(list);
    setIsOpen(!isOpen);
  };

  const handleAdd = async () => {
    setIsModified(true);

    setFAQList([...FAQList, { topic: "", answer: "" }]);

    await moduleBotConfigurationTracks.sendTrackClickButtonAddFAQ({
      merchantName: userData.Name,
      success: true,
      screenName: "Menu",
      error: "none",
    });
  };

  const validateCustomizableMenu = (menu: FAQField[]) => {
    let isValid = true;

    menu.forEach((option) => {
      isValid =
        isValid &&
        validateTextInput(option.topic) &&
        validateTextInput(option.answer);
    });
    return isValid;
  };

  useEffect(() => {
    setTabData((current) => {
      return {
        ...current,
        isInvalid: !validateCustomizableMenu(list),
      };
    });
  }, [list]);

  const handleOnChange = (event: Event) => {
    const { value } = event.target as HTMLBdsInputElement;
    setTopic(value as string);

    setIsModified(true);
  };

  const handleOnChangeTextArea = async (event: Event) => {
    const { value } = event.target as HTMLBdsInputElement;
    setAnswer(value as string);

    setIsModified(true);
  };

  useEffect(() => {
    list[index].topic = topic;
    list[index].answer = answer;

    setFAQList(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topic, answer]);

  useEffect(() => {
    if (isModified) {
      setTabData((current) => {
        return {
          ...current,
          isModified,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModified]);
  return (
    <MainContainer className="faqFields">
      <LabelContainer>
        <>
          <InputTextContainer listLength={list.length}>
            <InputText
              label={tabsTexts.customizableMenu.optionMenu}
              placeholder={tabsTexts.customizableMenu.titleOptionMenu}
              onChange={handleOnChange}
              value={list[index].topic}
              required={true}
              requiredErrorMessage={tabsTexts.error.requiredField}
            />
          </InputTextContainer>

          {list.length > 1 && (
            <ButtonIconContainer>
              <bds-button-icon
                variant="ghost"
                icon="trash"
                onClick={handleClickDeleteButton}
              />
            </ButtonIconContainer>
          )}
        </>
      </LabelContainer>

      <InputContainer>
        <InputText
          isTextArea={true}
          rows={4}
          placeholder={tabsTexts.customizableMenu.responseMenuDescription}
          type="email"
          label={tabsTexts.customizableMenu.responseMenu}
          value={list[index].answer}
          onChange={handleOnChangeTextArea}
          required={true}
          requiredErrorMessage={tabsTexts.error.requiredField}
        />
      </InputContainer>

      {FAQList.length - 1 === index && FAQList.length < MAX_FAQS && (
        <ButtonContainer>
          <Button
            isAdd
            isFAQ
            text={tabsTexts.customizableMenu.buttonAddOption}
            onClick={handleAdd}
          />
        </ButtonContainer>
      )}

      {isAlert && (
        <AlertDelete
          handleRemove={handleRemove}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          merchantName={userData.Name}
          screenName="Menu"
          type="menu-topic"
        />
      )}
    </MainContainer>
  );
};
